const API_URL = "https://api.logpass.dev"
let API_LANGUAGE = "pl-PL,pl;q=0.9,en-US;q=0.8,en;q=0.7";
let API_AUTH_TOKEN : string | null = null;

export function setLanguage(language : string) {
    API_LANGUAGE = language;
}

export function setAuthToken(token : string | null) {
    API_AUTH_TOKEN = token;
}

export interface ApiSubError
{
    code: string;
    message: string;
    pointer?: string;
    extra_data?: any;
}

export class ApiError extends Error {
    code : number;
    code_str : string;
    message : string;
    errors: ApiSubError[];

    constructor(code : number, code_str : string, message : string, errors : ApiSubError[] = []) {
        super(message);
        this.code = code;
        this.code_str = code_str;
        this.message = message;
        this.errors = errors;
    }
}

async function query(method : "GET" | "POST" | "PATCH" | "DELETE", path : string, data : any = null) : Promise<any>
{
    if(!path.startsWith("/"))
        throw Error("API path should start with /");
    if(method !== "GET" && !data)
        throw Error("Missing body data");

    let headers : any = {
        'Accept-Language': API_LANGUAGE
    };
    if(data)
        headers['Content-Type'] = 'application/json';
    if(API_AUTH_TOKEN)
        headers['Authorization'] = `Bearer ${API_AUTH_TOKEN}`;
    try {
        let query = await fetch(API_URL + path, {
            method: method,
            cache: 'no-cache',
            headers: headers,
            ...(data && {
                body: JSON.stringify(data)
            })    
        });
        if(query.status == 204) {
            return {};
        }
        let json : any = null;
        try {
            json = await query.json();
        } catch(e) {}
        if(query.status >= 400 && query.status < 500) {
            if(!json)
                throw new ApiError(query.status, query.status.toString(), query.status.toString());
            throw new ApiError(query.status, json["code"], json["message"] || json["error_description"], json["errors"]);
        }
        if(!json)
            return {};
        return json["data"] || json;
    } catch(e) {
        if(e instanceof ApiError)
            throw e;
        throw new ApiError(-1, "other", e.message);
    }
}

export interface ExchangeCodeParams {
    grant_type : string,
    client_id : string,
    code : string,
    redirect_uri : string,
    code_verifier : string
}

export interface ExchangeCodeResult {
    token_type : string,
    access_token : string,
    expires_in : number,
    refresh_token : string,
    scope : string
}

export async function exchangeCode(params : ExchangeCodeParams) : Promise<ExchangeCodeResult> {
    return query("POST", "/auth/o/tokens/", params);
}

export interface RefreshTokenParams {
    grant_type : string,
    client_id : string,
    refresh_token : string
}

export async function refreshToken(params : RefreshTokenParams) : Promise<ExchangeCodeResult> {
    return query("POST", "/auth/o/tokens/", params);
}

export async function getUserInfo() : Promise<any> {
    return query("GET", "/auth/o/userinfo/");
}
