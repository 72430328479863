import React from 'react';
import { Grid } from '@material-ui/core';
import { Link, Redirect } from "react-router-dom";
import URLParse from "url-parse";
import * as API from '../api';
import LoadingView from './loading';
import Config from '../config.json';

export default function LoginView(props: any) {
  const [redirect, setRedirect] = React.useState<JSX.Element | null>(null);

  React.useEffect(() => {
    (async () => {
      try {
        const url = URLParse(window.location.href, true);
        const query = url.query;
        if(query.error) {
          throw new Error(`Authentication failed.\n${query.error_description || ""}`)
        }
        if(!query.code) {
          throw new Error("Invalid url (missing code)");
        }
        let exchangeCodeParams : API.ExchangeCodeParams = {
          grant_type: "authorization_code",
          client_id: Config.client_id,
          code: query.code as string,
          redirect_uri: `${window.location.origin}/login`,
          code_verifier: "vjjzYa3PRQYi49yuAPEXywwF42RUifyi07Ty0cwBjRI4Zlt2KK"          
        };
        const loginResponse = await API.exchangeCode(exchangeCodeParams);
        if(!loginResponse.access_token || !loginResponse.refresh_token) {
          throw new Error("Invalid response (missing access or refresh token)");
        }
        localStorage.setItem("session", JSON.stringify(loginResponse));
        setRedirect(<Redirect to={{
            pathname: `/`,
            state: {
                loginParams: loginResponse
            }
        }}></Redirect>);
      } catch(e) {
        setRedirect(<Redirect to={{
            pathname: "/error",
            state: { 
                error: e.message
            }
          }}></Redirect>);
      }
    })();
  }, []);

  return <LoadingView 
    title={"Loading..."}
    redirect={redirect} 
  />;
}
