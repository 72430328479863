import React from 'react';
import { Button, Checkbox, Grid, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Link } from "react-router-dom";
import Config from '../config.json';
import * as API from '../api';

export default function IndexView(props: any) {
  const [session, setSession] = React.useState<API.ExchangeCodeResult | null>(null);
  const [info, setInfo] = React.useState<JSX.Element | null>(null);
  const [checked, setChecked] = React.useState([0, 1, 2]);

  const scopes = [
    ["email", "Email"],
    ["phone_number", "Phone number"],
    ["address", "Address"],
    ["invoice", "Invoice"],
    ["profile", "Profile"],
    ["verification:biometric", "Biometric verification"],
  ]

  const doLogin = () => {
    const scope = [0, 1, 2, 3, 4].map((value) => {
      if(checked.indexOf(value) !== -1)
        return scopes[value][0];
      return "";
    }).join(" ");
    const code_challenge = "vjjzYa3PRQYi49yuAPEXywwF42RUifyi07Ty0cwBjRI4Zlt2KK";
    const loginParams = {
      client_id: Config.client_id,
      redirect_uri: `${window.location.origin}/login`,
      scope: scope,
      response_type: Config.response_type,
      code_challenge: code_challenge,
      code_challenge_method: Config.code_challenge_method
    }
    //profile email phone_number address invoice verification:biometric
    const queryParams = (new URLSearchParams(loginParams)).toString();
    const url = `${Config.oauth2_url}?${queryParams}`;
    window.location.href = url;
  }

  const doLogout = () => {
    localStorage.removeItem("session");
    API.setAuthToken(null);
    setSession(null);
    setInfo(null);
  }

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const renderScopes = () => {
    return <div>
      <h3 style={{margin: 0}}>Select required scopes:</h3>
      <List>
        {[0, 1, 2, 3, 4, 5].map((value) => {
          const labelId = `checkbox-list-label-${value}`;

          return (
            <ListItem key={value} role={undefined} dense button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={scopes[value][1]} />
            </ListItem>
          );
        })}
      </List>
    </div>
  }

  React.useEffect(() => {
    const sesisonString = localStorage.getItem("session");
    if (!sesisonString)
      return;
    const session: API.ExchangeCodeResult = JSON.parse(sesisonString);
    if (!session || !session.access_token || !session.refresh_token)
      return;
    setSession(session);
    (async () => {
      try {
        const refreshParams: API.RefreshTokenParams = {
          client_id: Config.client_id,
          refresh_token: session.refresh_token,
          grant_type: "refresh_token"
        }
        let refreshResponse = await API.refreshToken(refreshParams);
        if (!refreshResponse.refresh_token)
          refreshResponse.refresh_token = session.refresh_token;
        localStorage.setItem("session", JSON.stringify(refreshResponse));
        setSession(refreshResponse);
        API.setAuthToken(refreshResponse.access_token);
      } catch (e) {
        console.error(e);
        setInfo(<>Session expired. Login again.</>)
        setSession(null);
      }
      try {
        const userInfo = await API.getUserInfo();
        setInfo(<>{JSON.stringify(userInfo, null, 2).split('\n').map(str => <>{str}<br /></>)}</>);
      } catch (e) {
        console.error(e);
        setInfo(<>Can't get user info.</>)
      }
    })();
  }, []);

  return <Grid container direction="column" justify="space-between" alignItems="center" spacing={2} style={{ minHeight: "200px" }}>
    <Grid item>
      <h1 style={{ textAlign: 'center', margin: 0 }}>{session ? "You are logged in" : "You are not logged in"}</h1>
    </Grid>
    <Grid item>
      {session ? info : renderScopes()}
    </Grid>
    <Grid item>
      {
        session ?
          <Button variant="contained" color="secondary" onClick={doLogout} style={{ width: "248px", height: "41px" }}>Logout</Button>
          : <img src={process.env.PUBLIC_URL + '/continue.png'} onClick={doLogin} style={{ width: "248px" }} />
      }
    </Grid>
  </Grid>;
}
