import React from 'react';
import './App.css';
import { FormControl, Select, MenuItem } from '@material-ui/core';
import { BrowserRouter as Router, Route, Switch, Link, Redirect, useHistory } from 'react-router-dom';
import IndexView from './views/index';
import ErrorView from './views/error';
import LoginView from './views/login';

function App() {
  return (
      <div className="App">
        <header>
        </header>
        <div className="fullSeparator"></div>
        <main>
          <Router>
            <Switch>
              <Route path="/" exact component={IndexView} />
              <Route path="/login" exact component={LoginView} />
              <Route component={ErrorView} />
            </Switch>
          </Router>
        </main>
        <div className="fullSeparator"></div>
        <footer>
          oauth2.logpass.info
        </footer>
      </div>
  );
}

export default App;
